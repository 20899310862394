// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

/*User CSS*/

.navbar-primary-gradient {
    background: rgb(229,76,37);
    background: -moz-linear-gradient(90deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    background: linear-gradient(90deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e54c25",endColorstr="#f26527",GradientType=1);
}

.bg-primary-gradient {
    background: rgb(229,76,37);
    background: -moz-linear-gradient(90deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    background: linear-gradient(90deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e54c25",endColorstr="#f26527",GradientType=1);
}

.bg-primary-gradient-vert {
    background: rgb(229,76,37);
    background: -moz-linear-gradient(360deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    background: -webkit-linear-gradient(360deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    background: linear-gradient(360deg, rgba(229,76,37,1) 0%, rgba(234,89,40,1) 50%, rgba(242,101,39,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e54c25",endColorstr="#f26527",GradientType=1);
}

.bg-img-cover {
    background-repeat: no-repeat !important;
    background-position: center center !important; 
    background-size:cover !important;
}

.bg-mark {
    background:$primary;
}

.rounded-wrap {
    border-radius: 60px !important;
    -webkit-border-radius: 60px !important;
    -moz-border-radius: 60px !important;
}

.border-gray {
    border-color: #aaaaaa !important;
}
.text-dark-2 {
    color: #808080;
}

.bg-white-transp {
    &:before {
        content: " ";
        background: $white;
        opacity: (0.6);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        @extend .rounded-wrap;
    }
}

.green-circle-box {
    width:260px;
    height:260px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    padding: 40px;
    @extend .mx-auto;
    @extend .bg-primary;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
}

.card {
    &.card-hover {
        &:hover {
            background-color: #e9e9e9;
        }
    }
}

// Global Implementation of the custom list design
// Bulleted and custom icon list
.list-group {
    &.list-bullet {
        .list-group-item {
            padding-left: 15px;
            &::before {
                font-family: 'bootstrap-icons';
                content: "\f287";
                font-size: 10px;
                margin-left: -15px;
                margin-right: 10px;
                color: $primary;
            }
        }
    }
}

div,
section {
    &.web-content {
        ul {
            margin: 20px 0px;
            @extend .list-group;
            @extend .list-bullet;
            @extend .list-group-borderless;
            li {
                @extend .list-group-item;
            }
        }
        h2,h3,h4,h5,h6 {
            font-size: 22px;
            margin: 20px 0px;
        }
    }
}

.dropzone {    
    border: 0px solid transparent !important;
}

.fs-12 {
    font-size:12px;
}

.section-heading {
    font-size: 32px;
}
.section-subheading {
    font-family:$font-family-heading;
    font-size: 24px;
}

@include media-breakpoint-down(sm) {
    .navbar-primary-gradient {
        position:relative;
        padding: 15px;
        margin-bottom: 30px;
        .navbar {
            padding: 6px 15px;
            &.navbar-dark {
                background-color: $white;
                border-radius: 60px;
                margin-top:36px;
                margin-bottom: -60px;
                box-shadow: $box-shadow-lg;
                --#{$prefix}navbar-toggler-icon-bg: #{escape-svg($navbar-light-toggler-icon-bg)};
            }
        }
    }

    .btn-xs-normal {
        font-size: 12px;
        padding: 0.5rem 1.2rem;
        .lead {
            font-size: 0.8rem;
        }
    }

    .section-heading {
        font-size: 28px;
    }
    .section-subheading {
        font-size: 18px;
    }

    .text-xs-12 {
        font-size: 12px !important;
    }
    .text-xs-13 {
        font-size: 13px !important;
    }
    .text-xs-14 {
        font-size: 14px !important;
    }
    .text-xs-16 {
        font-size: 16px !important;
    }

    // .navbar-primary-gradient {
    //     .navbar {
    //         .navbar-nav {
    //             position:relative;
    //             text-align:center;
    //             margin-bottom: -75px;
    //             .nav-item {
    //                 margin-left:10px;
    //                 margin-right:10px;
    //                 .nav-link {
    //                     width:94px;
    //                     height:94px;
    //                     padding: 6px;
    //                     line-height: 20px;
    //                     background: $primary;
    //                     border-radius: 50%;
    //                     font-size:1rem;
    //                     border: 2px solid $white;
    //                     display:flex;
    //                     -webkit-display:flex;
    //                     -moz-display:flex;
    //                     justify-content:center;
    //                     -webkit-justify-content:center;
    //                     -moz-justify-content:center;
    //                     align-items:center;
    //                     -webkit-align-items:center;
    //                     -moz-align-items:center;
    //                     &:hover,
    //                     &:focus,
    //                     &:active,
    //                     &.active {
    //                         // background: $success;
    //                         color: #f2ef00;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}